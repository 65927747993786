import React, { FunctionComponent } from "react";
import ProfileIcon from "web-common/src/components/Header/profileIcon";
import { InternalLink } from "web-common/src/types/SanityTypes";
import { Link } from "web-common/src/components/Link";

interface ProfileToggleInterface {
  data: {
    sanitySignUp?: InternalLink;
  };
}

const ProfileToggle: FunctionComponent<ProfileToggleInterface> = ({ data }) => {
  return (
   null
  );
};
export default ProfileToggle;
